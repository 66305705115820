<template>
  <div class="row justify-content-center px-8 px-lg-10">
    <div class="col-xl-12 col-xxl-7 justify-content-center">
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <!--begin::Form Wizard-->
        <form class="form" @submit.prevent="handleSubmit(_handleSubmit)">
          <!--begin::Step 1-->
          <div data-wizard-type="step-content" data-wizard-state="current">
            <div class="row">
              <div class="col-xl-12">
                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('bank_management.name') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('bank_management.name')"
                      type="text"
                      v-model="formData.name"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('bank_management.short_name') }}</label>
                  <validation-provider rules="required" v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading">
                    <input
                      class="form-control"
                      :name="$t('bank_management.short_name')"
                      type="text"
                      v-model="formData.short"
                      :class="classes"
                    />
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('bank_management.country') }}</label>
                  <validation-provider
                    rules="required" :name="$t('bank_management.country')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedCountry"
                      :options="countries"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='code'
                      label='code'
                      :placeholder="$t('bank_management.country')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.code | uppercase }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.code | uppercase }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('bank_management.iban_type') }}</label>
                  <validation-provider
                    rules="required" :name="$t('bank_management.iban_type')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="formData.type"
                      :options="ibanTypes"
                      :searchable="false"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      open-direction="bottom"
                      :placeholder="$t('bank_management.iban_type')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-xl-3 col-lg-3 col-form-label">{{ $t('bank_management.status') }}</label>
                  <validation-provider
                    rules="required" :name="$t('bank_management.status')" 
                    v-slot="{ classes, errors }" class="col-lg-9 col-xl-9" v-if="!isLoading"
                  >
                    <multiselect
                      v-model="selectedStatus"
                      :options="allStatus"
                      :searchable="false"
                      :allow-empty="false"
                      :close-on-select="true"
                      :clear-on-select="false"
                      :preserve-search="true"
                      open-direction="bottom"
                      deselect-label=''
                      select-label=''
                      selected-label=''
                      track-by='name'
                      label='name'
                      :placeholder="$t('bank_management.status')"
                      :class="classes"
                    >
                      <template slot='singleLabel' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='option' slot-scope='{ option }'>{{ option.name }}</template>
                      <template slot='noOptions'>{{ 'List is empty' }}</template>
                    </multiselect>
                    <span class="error__message">{{ errors[0] }}</span>
                  </validation-provider>
                  <div v-else class="col-lg-9 col-xl-9">
                    <Skeleton height="38px" />
                    <span class="error__message"></span>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <!--begin::Actions-->
          <div class="d-flex justify-content-end border-top mt-5 pt-10">
            <button
              type="submit"
              class="btn btn-success font-weight-bolder text-uppercase px-9 py-4"
              :disabled="isSubmitting"
            >
              {{ $t('commons.submit') }}
            </button>
          </div>
          <!--end::Actions-->
        </form>
        <!--end::Form Wizard-->
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Skeleton } from 'vue-loading-skeleton';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import i18n from '@/config/_i18n'

const all_status = [
  { value: 10, name: i18n.t("institutions.active_status") },
  { value: 20, name: i18n.t("institutions.passive_status") }
]

export default {
  name: "NewBank",
  data() {
    return {
      formData: {
        name: "",
        short: "",
        country_id: "",
        type: "IBAN",
        status: ""
      },
      allStatus: all_status,
      ibanTypes: ["IBAN", "UBAN"],
      selectedCountry: null,
      selectedStatus: null
    };
  },
  components: {
    Skeleton,
    Multiselect
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.bankManagement.isLoading,
      isSubmitting: (state) => state.bankManagement.isSubmitting,
      countries: (state) => state.bankManagement.countries
    }),
  },
  methods: {
    ...mapActions('bankManagement', ['GET_STORE_DATA', 'STORE']),
    _handleSubmit() {
      this.formData.country_id = this.selectedCountry?.id;
      this.formData.status = this.selectedStatus?.value;
      this.STORE(this.formData);
    }
  },
  created() {
    this.GET_STORE_DATA();
    this.formData.status = all_status[0].value
  },
};
</script>
